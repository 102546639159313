import React, { Component } from "react";
import "./ExperienceCard.css";

class ExperienceCard extends Component {
  render() {
    const experience = this.props.experience;
    const theme = this.props.theme;
    var listItems = (items) => {
      let rows = [];
      if (items instanceof Array) {
        items.forEach((desc) => {
          rows.push(
            <p
              className="experience-card-description"
              style={{ color: theme.secondaryText }}
            >
              {desc}
            </p>
          );
        });
      } else {
        rows.push(
          <p
            className="experience-card-description"
            style={{ color: theme.secondaryText }}
          >
            {items}
          </p>
        );
      }
      return rows;
    };

    return (
      <div
        className="experience-card"
        style={{ border: `1px solid ${experience["color"]}` }}
      >
        {experience["logo_path"] && (
          <div class Name="experience-card-logo-div">
            <img
              className="experience-card-logo"
              src={require(`../../assests/images/${experience["logo_path"]}`)}
              alt=""
            />
          </div>
        )}
        <div className="experience-card-body-div">
          <div className="experience-card-header-div">
            <div className="experience-card-heading-left">
              <h3
                className="experience-card-title"
                style={{ color: theme.text }}
              >
                {experience["title"]}
              </h3>
              <p
                className="experience-card-company"
                style={{ color: theme.text }}
              >
                <a
                  href={experience["company_url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {experience["company"]}
                </a>
              </p>
            </div>
            <div className="experience-card-heading-right">
              <p
                className="experience-card-duration"
                style={{ color: theme.secondaryText }}
              >
                {experience["duration"]}
              </p>
              <p
                className="experience-card-location"
                style={{ color: theme.secondaryText }}
              >
                {experience["location"]}
              </p>
            </div>
          </div>
          {/* <p
            className="experience-card-description"
            style={{ color: theme.text }}
          >
            
          </p> */}
          {listItems(experience["description"])}
        </div>
      </div>
    );
  }
}

export default ExperienceCard;
