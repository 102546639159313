/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Bharat's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable technical systems to create impact.",
  og: {
    title: "Bharat Gadde Portfolio",
    type: "website",
    url: "http://bharatgadde.com/",
  },
};

//Home Page
const greeting = {
  title: "Bharat Gadde",
  logo_name: "Bharat Gadde",
  nickname: "Bharat",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1For-jnSblnjYh19c90apdhHUU-E1gpwl",
  githubProfile: "https://github.com/bharat-gadde",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/bharat-gadde",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/gaddebharatkumar",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:gaddebharatkumar@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/bharat_gadde",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/bharat_gadde",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Creating application backend in Go, Java",
        "⚡ Develop data acceptance and storage solutions for websites",
        "⚡ Identify and address issues pertaining to stability of applications and application lifecycle",
        "⚡ Building responsive website front end using React",
      ],
      softwareSkills: [
        {
          skillName: "Go",
          fontAwesomeClassname: "logos:go",
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
        },
        {
          skillName: "Spring",
          fontAwesomeClassname: "logos:spring-icon",
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "logos:react",
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "logos:git-icon",
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos:javascript",
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "logos:html-5",
        },
        {
          skillName: "ELK",
          fontAwesomeClassname: "logos:elasticsearch",
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining application instances along with integration of databases",
        "⚡ Tracing and profiling applications",
      ],
      softwareSkills: [
        {
          skillName: "Docker",
          fontAwesomeClassname: "logos:docker-icon",
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "logos:kubernetes",
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "logos:aws",
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "logos:microsoft-azure",
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "logos:firebase",
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Stack Overflow",
      iconifyClassname: "logos:stackoverflow-icon",
      profileLink: "https://stackoverflow.com/users/7409103/bharat-gadde",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@gaddebharatkumar",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/gaddebharatkumar",
    },
  ],
};

const degrees = {
  degrees: [
    // {
    //   title: "GITAM Univerisity",
    //   subtitle: "M.B.A Finance",
    //   logo_path: "gitamLogo.png",
    //   alt_name: "Sathyabama",
    //   duration: "2019 - 2021",
    //   website_link: "https://www.gitam.edu",
    //   descriptions: [
    //     "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
    //     "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
    //     "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
    //   ],
    // },
    {
      title: "Sathyabama University",
      subtitle: "B.E. in Electronics and Control",
      logo_path: "SathyabamaUniversityLogo.png",
      alt_name: "Sathyabama",
      duration: "2012 - 2016",
      website_link: "https://www.sathyabama.ac.in",
      descriptions: [
        "⚡ I have studied basic electronics engineering subjects like MicroProcessors, Electronic circuits along with basics of Software Engineering subjects DS, Algorithms, DBMS etc.",
        "⚡ Apart from this, I have done courses on Backend and Full Stack Development.",
        "⚡ Passed with Distinction",
      ],
    },
  ],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked mostly as Backend Go and Java Developer. I have also worked with some well established companies as Full stack Developer. I love contributing to opensource and helping others in StackOverFlow.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "SDE II",
          company: "AngelOne",
          company_url: "https://www.angelone.in",
          logo_path: "angeloneLogo.png",
          duration: "Dec 2021 - PRESENT",
          location: "Work From Home",
          description:
            "I am working on Funds Related Projects. The projects involve designing and developing backend applications for significantly enhancing the overall funds experience for Clients. I am currently working on Payment Gateway 2. Our goal is to make system which scales and provides seamless payment experience to clients",
          color: "#0879bf",
        },
        {
          title: "Senior Software Engineer",
          company: "Wissen Technologies",
          company_url: "https://www.wissen.com",
          logo_path: "wissenLogo.png",
          duration: "Jul 2021 - Dec 2021",
          location: "Bangalore, Karnataka",
          description:
            "I have worked on Insider trading reporter application using which Morgan Stanley detects any trade done by Morgan Stanley employees using confidential information.",
          color: "#17294d",
        },
        {
          title: "Technology Analyst",
          company: "Edgeverve Systems",
          company_url: "https://www.edgeverve.com",
          logo_path: "edgeverveLogo.png",
          duration: "Jun 2019 - Jul 2021",
          location: "Pune, Maharashtra",
          description:
            "FBC, Finacle Blockchain Trade Connect is an application that enabled its users to perform trade online. I have developed multiple frameworks as per technology standards and migrated the monolith to MicroApp architecture enabling horizontal scaling. Also worked on moving from tradition on prem server deployment to cloud (Docker, Kubernetes)",
          color: "#004a8f",
        },
        {
          title: "Senior Systems Engineer",
          company: "Infosys Ltd",
          company_url: "https://www.infosys.com",
          logo_path: "InfosysLogo.png",
          duration: "May 2016 - Jun 2019",
          location: "Bhubaneswar, Odisha",
          description:
            "Worked mostly on PORT (Plan Of Record Tool) along with multiple internal Infosys Applications. PORT is a MacOS Application written in swift, using java service layer is being used by Apple to plan & manage any product releases.",
          color: "#3781c2",
        },
      ],
    },
    {
      title: "Projects",
      experiences: [
        {
          title: "Payment Gateway 2.0",
          company: "AngelOne",
          company_url: "https://www.angelone.in",
          duration: "Dec 2021 - PRESENT",
          location: "Work From Home",
          description: [
            "⚡ PG2 is the payment gateway through which AngelOne's clients adds funds for trading, charges etc",
            "⚡ Highly scalable solution facilitating almost 3 Lakh transactions a day and 20 transactions per Second during peak market hours",
            "⚡ Reduced latency through out the transaction cycle for better, fast and seamless experience to customers ",
            "⚡ Due to the robust architecture and design, it is very easy to integrate and can sustain a large amount of load",
            "⚡ Improved success rate with almost zero need of manual intervention for reconciliation",
          ],
          color: "#0879bf",
        },
        {
          title: "Insider Trading Reporter",
          company: "Wissen Technologies",
          company_url: "https://www.wissen.com",
          duration: "Jul 2021 - Dec 2021",
          location: "Bangalore, Karnataka",
          description:
            "Detects any signs of insider trading by gathering and analyzing huge sets of trade data from various sources",
          color: "#0071C5",
        },
        {
          title: "Finacle Blockchain Trade Connect",
          company: "Edgeverve Systems",
          company_url: "https://www.edgeverve.com",
          duration: "Jun 2019 - Jul 2021",
          location: "Pune, Maharashtra",
          description: [
            "⚡ Simplifies the current manual process involved in finance for trade",
            "⚡ Reduced time for creating, accepting/declining and processing the trade documents by 50%",
            "⚡ Processing of trade with Highest degree of security and transparency with the use of Block-chain",
          ],
          color: "#0071C5",
        },
        {
          title: "PORT (Plan Of Record Tool)",
          company: "Infosys Ltd",
          company_url: "https://www.infosys.com",
          duration: "Mar 2017 - Jun 2019",
          location: "Bhubaneswar, Odisha",
          description: [
            "⚡ PORT is a macOS application used to capture accurate information related to New Product Introduction (NPI) planning",
            "⚡ Simplifies the process of data consolidation, POR maintenance and NPI milestone tracking",
            "⚡ Version control capabilities and on demand reporting across various cross functional and RDNS teams",
            "⚡ Written in Swift, with significant modules of Java, Objective C, Python.",
          ],
          color: "#0071C5",
        },
        {
          title: "Sprout",
          company: "Infosys Ltd",
          company_url: "https://www.infosys.com",
          duration: "May 2017 - Jun 2019",
          location: "Bhubaneswar, Odisha",
          description: [
            "⚡ Sprout is a high level macOS framework written in objective C and being successfully used by many supply and chain management application",
            "⚡ It accurately displays highly sophisticated and complex data in a simple manner",
            "⚡ Users can choose from a wide variety of options on how data should be displayed and filter out the specific kind of data, which internally improves productivity and user experience with the application",
            "⚡ Downloading to keynote and Excel are one of the best features, helps users to visualise and share the data in the same simplest format",
          ],
          color: "#0071C5",
        },
        {
          title: "Scheduler - Native iOS Calender",
          company: "Infosys Ltd",
          company_url: "https://www.infosys.com",
          duration: "Nov 2016 - May 2017",
          location: "Bhubaneswar, Odissa",
          description: [
            "⚡ Built with Swift & Python, calender app gives user ability to add events and share them with peers",
            "⚡ User can create multiples events with just a tap and share them with others within this so itself or through mail",
            "⚡ Instead of individual events the whole calendar can be delegated to a specific user along with modify / no modify access to delegate.",
          ],
          color: "#0071C5",
        },
        {
          title: "Path",
          company: "Infosys Ltd",
          company_url: "https://www.infosys.com",
          duration: "May 2016 - Nov 2016",
          location: "Bhubaneswar, Odisha",
          description: [
            "⚡ Path is a  UI application written in objective C using cocoa drawing which displays UI workflow",
            "⚡ It is designed in a way that it can adopt to numerous cases which can be configured at runtime",
            "⚡ Written as a standalone application but later extended as a framework and plugin",
          ],
          color: "#0071C5",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Personal Projects",
  description:
    "My Personal projects makes use of vast variety of latest technology tools. My best experience is to create Go and Java projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "bharatProfilePic.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Java, Go, Opensource and Cloud Development.",
  },
  addressSection: {
    title: "Address",
    subtitle: "Tanuku, Dist.-West Godavari, Andhra Pradesh - 534211",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/U5D735r64jKEC2pM8",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 9003088460",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
